import * as React from 'react'
import loadable from '@loadable/component'

const StandardPage = loadable(() => import('/src/templates/StandardPage'))

const Page = ({ postInfo }) => {
    const post = {
        title: '404: Page not found',
        content: `<p>Sorry, this page does not exist! You may want to head back to our <a href="/">homepage</a>.</p>`
    }

    return <StandardPage postInfo={postInfo} post={post} />
}

export default Page
